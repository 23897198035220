import React from 'react';
import Box from '@material-ui/core/Box';
import App from '../App';
import { LargeTitle, BodyText } from '../components/Typography';
import BottomHero from '../components/BottomHero';
import NavHeader from '../components/NavHeader';
import Seo from '../components/Seo';
import { GlobalStyles } from '../shared/global';
import theme from '../theme';

const PrivacyPolicy = () => (
  <App>
    <Seo title="Privacy Policy" canonicalPath="privacy-policy" />
    <GlobalStyles />
    <NavHeader sticky navHeaderOffset={300} />
    <Box
      textAlign="center"
      style={{ background: theme.colors.BACKGROUND_SECONDARY, paddingTop: 200, paddingBottom: 200 }}
      width="100%"
      mx="auto"
    >
      <Box textAlign="center" width="80%" mx="auto" mb="50px">
        <LargeTitle>Privacy Policy</LargeTitle>
      </Box>

      <Box textAlign="left" width="80%" mx="auto">
        <BodyText>
          Protecting your private information is our priority. This Statement of Privacy applies to https://neuvybe.com
          and NeuVybe LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to NeuVybe LLC include https://neuvybe.com and NeuVybe. The NeuVybe website is
          a media and entertainment site. By using the NeuVybe website, you consent to the data practices described in
          this statement.
        </BodyText>
      </Box>

      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Collection of your Personal Information</b>
          <br />
          In order to better provide you with products and services offered on our Site, NeuVybe may collect personally
          identifiable information, such as your:
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          &emsp;&emsp;- &emsp;First and Last Name
          <br />
          &emsp;&emsp;- &emsp;E-mail Address
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          If you purchase NeuVybe's products and services, we collect billing and credit card information. This
          information is used to complete the purchase transaction.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          We do not collect any personal information about you unless you voluntarily provide it to us. However, you may
          be required to provide certain personal information to us when you elect to use certain products or services
          available on the Site. These may include: (a) registering for an account on our Site; (b) entering a
          sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected
          third parties; (d) sending us an email message; (e) submitting your credit card or other payment information
          when ordering and purchasing products and services on our Site. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or products you have requested from us. We
          also may gather additional personal or non-personal information in the future.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Use of your Personal Information</b>
          <br />
          NeuVybe collects and uses your personal information to operate its website(s) and deliver the services you
          have requested.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          NeuVybe may also use your personally identifiable information to inform you of other products or services
          available from NeuVybe and its affiliates.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Sharing Information with Third Parties</b>
          <br />
          NeuVybe does not sell, rent or lease its customer lists to third parties.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          NeuVybe may share data with trusted partners to help perform statistical analysis, send you email or postal
          mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using
          your personal information except to provide these services to NeuVybe, and they are required to maintain the
          confidentiality of your information.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          NeuVybe may disclose your personal information, without notice, if required to do so by law or in the good
          faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal
          process served on NeuVybe or the site; (b) protect and defend the rights or property of NeuVybe; and/or (c)
          act under exigent circumstances to protect the personal safety of users of NeuVybe, or the public.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Automatically Collected Information</b>
          <br />
          Information about your computer hardware and software may be automatically collected by NeuVybe. This
          information can include: your IP address, browser type, domain names, access times and referring website
          addresses. This information is used for the operation of the service, to maintain quality of the service, and
          to provide general statistics regarding use of the NeuVybe website.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Use of Cookies</b>
          <br />
          The NeuVybe website may use "cookies" to help you personalize your online experience. A cookie is a text file
          that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver
          viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the
          domain that issued the cookie to you.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a
          cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize
          NeuVybe pages, or register with NeuVybe site or services, a cookie helps NeuVybe to recall your specific
          information on subsequent visits. This simplifies the process of recording your personal information, such as
          billing addresses, shipping addresses, and so on. When you return to the same NeuVybe website, the information
          you previously provided can be retrieved, so you can easily use the NeuVybe features that you customized.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can
          usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you
          may not be able to fully experience the interactive features of the NeuVybe services or websites you visit.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Links</b>
          <br />
          This website contains links to other sites. Please be aware that we are not responsible for the content or
          privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read
          the privacy statements of any other site that collects personally identifiable information.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Security of your Personal Information</b>
          <br />
          NeuVybe secures your personal information from unauthorized access, use, or disclosure. NeuVybe uses the
          following methods for this purpose:
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>&emsp;&emsp;- &emsp;SSL Protocol</BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          When personal information (such as a credit card number) is transmitted to other websites, it is protected
          through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          We strive to take appropriate security measures to protect against unauthorized access to or alteration of
          your personal information. Unfortunately, no data transmission over the Internet or any wireless network can
          be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you
          acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our
          control; and (b) security, integrity, and privacy of any and all information and data exchanged between you
          and us through this Site cannot be guaranteed.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Children Under Thirteen</b>
          <br />
          NeuVybe does not knowingly collect personally identifiable information from children under the age of
          thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use
          this website.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>E-mail Communications</b>
          <br />
          From time to time, NeuVybe may contact you via email for the purpose of providing announcements, promotional
          offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services,
          we may receive a notification when you open an email from NeuVybe or click on a link therein.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          If you would like to stop receiving marketing or promotional communications via email from NeuVybe, you may
          opt out of such communications by sending an email to support@neuvybe.com.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>External Data Storage Sites</b>
          <br />
          We may store your data on servers provided by third party hosting vendors with whom we have contracted.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Changes to this Statement</b>
          <br />
          NeuVybe reserves the right to change this Privacy Policy from time to time. We will notify you about
          significant changes in the way we treat personal information by sending a notice to the primary email address
          specified in your account, by placing a prominent notice on our site, and/or by updating any privacy
          information on this page. Your continued use of the Site and/or Services available through this Site after
          such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement
          to abide and be bound by that Policy.
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Contact Information</b>
          <br />
          NeuVybe welcomes your questions or comments regarding this Statement of Privacy. If you believe that NeuVybe
          has not adhered to this Statement, please contact NeuVybe at:
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>NeuVybe LLC</b>
          <br />
          2035 Sunset Lake Road, Suite B-2
          <br />
          Newark, Delaware 19702
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Email Address:</b>
          <br />
          <a style={{ color: theme.colors.NEUVYBE_MAIN }} href="mailto:support@neuvybe.com">
            support@neuvybe.com
          </a>
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>
          <b>Telephone number:</b>
          <br />
          <a style={{ color: theme.colors.NEUVYBE_MAIN }} href="tel:+12029307545">
            1 (202) 930 7545
          </a>
        </BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <BodyText>Effective as of November 10, 2019</BodyText>
      </Box>
      <Box textAlign="left" width="80%" mx="auto" pt="25px">
        <hr />
      </Box>
    </Box>
    <BottomHero />
  </App>
);

export default PrivacyPolicy;
